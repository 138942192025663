import { storyblokEditable } from '@storyblok/js';
import PropTypes from 'prop-types';
import React from 'react';

import DynamicComponent from '../components/dynamic-component';
import Seo from '../components/elements/seo';
import Layout from '../components/layout';
import useStoryblok from '../hooks/use-storyblok';

export default function PageTemplate({ pageContext, location }) {
	const story = useStoryblok(pageContext.story, location);
	const blok = story.content;

	return (
		<Layout>
			<Seo {...blok.meta} path={location.pathname} />
			<div {...storyblokEditable(blok)}>
				{blok.body &&
					blok.body.map((child) => (
						<DynamicComponent location={location} blok={child} key={child._uid} />
					))}
			</div>
		</Layout>
	);
}

PageTemplate.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}).isRequired,
	pageContext: PropTypes.shape({
		story: PropTypes.shape({}),
	}).isRequired,
};
